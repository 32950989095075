import React from "react"
import { memo } from 'react';

const IconShare = memo(({ className }) => (
    <svg role="img" aria-label={"Cart"} className={"icon icon-person " + className} version="1.1"
         viewBox="0 0 32 32"
         xmlns="http://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink">
        <path fill="#ceaa72" className={"fill-current"} d="M18 7.762v-7.762l12 12-12 12v-7.932c-13.961-0.328-13.362 9.493-9.808 15.932-8.772-9.482-6.909-24.674 9.808-24.238z"/>
    </svg>
))

export default IconShare