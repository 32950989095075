import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import Seo from '../components/layout/Seo';
import {IconMail2, IconPhone, IconPlace} from '../components/icons/Icons';
import IconRefresh from '../icons/IconRefresh';
import IconTrash from '../icons/IconTrash';
import IconShare from '../icons/IconShare';
import Map from '../components/common/Map';
// Greg: decide if we use these icons: IconRefresh and IcoTrash

const ShoppingCart = () => {
  let sku = 'greg help!';
  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo title="SellUsYourJewelry.com | Shopping Cart & Quick Cash Offer" />

      <div className="thankyouHeader w-screen p-20">
        <body>
          <h1 className="text-center text-6xl font-black text-white ">SHOPPING CART</h1>
        </body>
      </div>
      <section>
        <div className="flex justify-center">
          <div className="grid w-full grid-cols-4 py-10 xl:w-10/12">
            <div className="col-span-4 w-full px-5 md:col-span-2 lg:col-span-3">
              <div className="flex flex-col justify-between lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <StaticImage
                    src={'../images/image1.png'}
                    alt={'Product Image'}
                    objectFit={'cover'}
                    placeholder={'none'}
                    loading={'eager'}
                    className="h-auto"
                  />
                </div>
                <div className="md:1/1 w-full p-5 lg:w-3/4">
                  <p className="text-2xl font-bold uppercase">Brand</p>
                  <p className="text-lg text-gray-600">
                    Model & Ref Number
                    <br />
                    Metal
                    <br />
                    Short Description
                  </p>
                  <br />
                  <br />
                  <p>
                    Item Number: &ensp;
                    <i>Sku Number</i>
                  </p>
                  <br />
                  <br />
                  <a
                    href={
                      'mailto:?subject=Check out this product!&amp;body=I found this and thought of you. Do you like it? https://www.grayandsons.com' +
                      sku
                    }
                    title="Share by Email"
                    className="px-2 text-2xl text-gray-500 hover:text-accent"
                  >
                    <IconShare />
                  </a>
                  <button className="px-2 text-2xl text-gray-500 hover:text-accent">
                    <IconTrash />
                  </button>
                  <br />
                  <br />
                </div>
                <div className="md:w-1/1 w-full p-5 lg:w-1/4">
                  G<span className="text-red-600">&amp;</span>S Price
                  <br />
                  <p className="text-2xl font-bold">$25,000</p>
                  <p className="text-red-600">45% Off New Retail</p>
                </div>
              </div>
            </div>
            <div className="col-span-4 w-full border-t-2 border-gray-400 p-5 text-lg md:col-span-2 md:border-l-2 md:border-t-0 lg:col-span-1">
              <p className="pb-2 text-2xl font-bold uppercase text-accent">Cart Details</p>
              <div>
                <form>
                  <div className="flex flex-row py-2">
                    <label htmlFor="PromoCode">
                      <nobr>Promo Code: &ensp;</nobr>
                    </label>

                    <input
                      className="h-10 w-full border"
                      type="text"
                      id="PromoCode"
                      name="PromoCode"
                    />
                    <button
                      className={
                        ' group flex w-15 items-center  justify-center bg-white text-xl text-accent hover:bg-accent hover:text-white'
                      }
                    >
                      {/*<IconRefresh className={' transform duration-300 hover:rotate-90'} />*/}
                    </button>
                  </div>
                  <div className="flex flex-row">
                    <label htmlFor="CalculateShipping">
                      <nobr>Zip Code: &ensp;</nobr>
                    </label>

                    <input
                      className="h-10 w-full border"
                      type="text"
                      id="CalculateShipping"
                      name="CalculateShipping"
                    />
                    <button
                      className={
                        ' group flex w-15 items-center  justify-center bg-white text-xl text-accent hover:bg-accent hover:text-white'
                      }
                    >
                      <IconRefresh className={' transform duration-300 hover:rotate-90'} />
                    </button>
                  </div>

                  <br />
                  <div>
                    <div>
                      Subtotal: &ensp;
                      <b>$25,000</b>
                      <br />
                      Shipping: &ensp;
                      <b>$25,000</b>
                      <br />
                      Sales Tax: &ensp;
                      <b>$25,000</b>
                      <br />
                      <br />
                    </div>

                    <div className="border-t py-2">
                      TOTAL: &ensp;{' '}
                      <p className="text-xl">
                        <b>$35,000</b>
                      </p>
                    </div>
                  </div>
                  <button className="m-4 w-full rounded-full border-2 border-transparent bg-accent py-5 font-bold text-white hover:border-accent hover:bg-white hover:text-accent">
                    CHECKOUT
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={'container mx-auto mt-16 flex flex-wrap gap-y-16 px-4 md:p-0'}>
        <section className={'w-full'}>
          <h2 className={'text-center text-4xl uppercase'}>Have Questions?</h2>
        </section>
        <section className={'w-full'}>
          <p className={'mx-auto w-full text-center text-lg lg:w-1/2 lg:text-xl 2xl:w-2/5'}>
            Our highly trained specialists, coupled with our international sales reach, allow us to
            offer the very best prices for your jewelry.
          </p>
        </section>

        <section className={'flex w-full flex-wrap gap-16 lg:flex-nowrap'}>
          <div
            className={
              'align-center flex h-full w-full flex-col justify-center py-24 text-center lg:w-1/3'
            }
          >
            <div className={'mb-8 text-5xl text-accent'}>
              <IconPlace />
            </div>
            <div className={'text-3xl uppercase'}>Visit Us</div>
            <div className={'mt-16 text-link'}>
              9595 Harding Avenue, Bal Harbour Florida 33154
            </div>
          </div>
          <div
            className={
              'align-center flex w-full flex-col justify-center border-l border-r border-accent text-center lg:w-1/3'
            }
          >
            <div className={'mb-8 text-5xl text-accent'}>
              <IconPhone />
            </div>
            <div className={'text-3xl uppercase'}>Call Us</div>
            <div className={'mt-16 text-accent'}><a href={"tel:+13057706955"}>tel: 305 770 6955</a></div>
          </div>
          <div className={'align-center flex w-full flex-col justify-center text-center lg:w-1/3'}>
            <div className={'mb-8 text-5xl text-accent'}>
              <IconMail2 />
            </div>
            <div className={'text-3xl uppercase'}>Email Us</div>
            <div className={'mt-16 text-accent'}><a href={"mailto:sell@grayandsons.com"}>sell@grayandsons.com</a></div>
          </div>
        </section>
      </div>

      <section className={'mt-16'}>
        <Map />
      </section>
    </Layout>
  );
};

export default ShoppingCart;
