import React from "react"
import { memo } from 'react';

const IconRefresh = memo(({ className }) => (
    <svg role="img" aria-label={"Refresh"} className={"icon icon-refresh " + className} version="1.1"
         viewBox="0 0 32 32"
         xmlns="http://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink">
          <path fill="#ceaa72" className={"fill-current"} d="M27.802 5.197c-2.925-3.194-7.13-5.197-11.803-5.197-8.837 0-16 7.163-16 16h3c0-7.18 5.82-13 13-13 3.844 0 7.298 1.669 9.678 4.322l-4.678 4.678h11v-11l-4.198 4.197z"/>
          <path fill="#ceaa72" className={"fill-current"} d="M29 16c0 7.18-5.82 13-13 13-3.844 0-7.298-1.669-9.678-4.322l4.678-4.678h-11v11l4.197-4.197c2.925 3.194 7.13 5.197 11.803 5.197 8.837 0 16-7.163 16-16h-3z"/>
    </svg>
))

export default IconRefresh